/**
 * @generated SignedSource<<8a153272b263b9fcaa1a93d23c3f9ee1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ContentNotificationEmailCtaButton = "disabled" | "enabled" | "%future added value";
export type EventVisibility = "cohort" | "member_group" | "public" | "%future added value";
export type OccurrenceReminderAudience = "all" | "non_rsvps" | "rsvps" | "%future added value";
export type OccurrenceStatus = "draft" | "published" | "%future added value";
export type WebFormQuestionType = "link" | "multiple_select" | "ranking" | "rating" | "single_select" | "written_answer" | "%future added value";
export type EditEventInput = {
  allowGuestAccess?: boolean | null;
  capacity?: number | null;
  collectFeedback?: boolean | null;
  content?: EditContentInput | null;
  datetimeRange?: ReadonlyArray<string> | null;
  guestCapacity?: number | null;
  hosts?: ReadonlyArray<OccurrenceHostInput> | null;
  initialMemberGroupIds?: ReadonlyArray<string> | null;
  meetingProviderId?: string | null;
  meetingUrl?: string | null;
  memberGroupIdsToAdd?: ReadonlyArray<string> | null;
  memberGroupIdsToRemove?: ReadonlyArray<string> | null;
  notificationEmailBody?: string | null;
  notifyMembersOnChange?: boolean | null;
  occurrenceId: string;
  physicalAddress?: GoogleMapAddressComponents | null;
  reminders?: ReadonlyArray<OccurrenceReminderInput> | null;
  status?: OccurrenceStatus | null;
  timezone?: string | null;
  videoRoomType?: string | null;
  visibility?: EventVisibility | null;
  zoomMeetingId?: string | null;
  zoomMeetingType?: number | null;
};
export type GoogleMapAddressComponents = {
  address: string;
  displayAddress?: string | null;
  image?: string | null;
  lat: number;
  lng: number;
  name?: string | null;
  placeId: string;
};
export type EditContentInput = {
  attachments?: ReadonlyArray<ContentAttachmentInput> | null;
  contentLabelId?: string | null;
  coverAudio?: string | null;
  coverAudioAssetId?: string | null;
  coverPhoto?: string | null;
  coverPhotoAssetId?: string | null;
  coverVideo?: string | null;
  coverVideoAssetId?: string | null;
  coverVideoDurationSeconds?: number | null;
  ctaText?: string | null;
  description?: string | null;
  isAiGenerated?: boolean | null;
  name?: string | null;
  notificationEmailCtaButton?: ContentNotificationEmailCtaButton | null;
  notificationEmailSubject?: string | null;
  releaseDatetime?: string | null;
  releaseNow?: boolean | null;
  richEditorDescriptionContent?: string | null;
  richEditorNotificationEmailBodyContent?: string | null;
  sendNotificationEmail?: boolean | null;
  showComments?: boolean | null;
  showCoverPlayIcon?: boolean | null;
  submissionLink?: string | null;
  thumbnailAssetId?: string | null;
  thumbnailUrl?: string | null;
  webFormRevision?: CreateWebFormRevisionInput | null;
};
export type ContentAttachmentInput = {
  id?: string | null;
  mediaAssetId?: string | null;
  mediaUrl?: string | null;
  name?: string | null;
};
export type CreateWebFormRevisionInput = {
  contentUsageId?: string | null;
  questions: ReadonlyArray<WebFormQuestionInput>;
  webFormId: string;
};
export type WebFormQuestionInput = {
  answerKey?: WebFormQuestionAnswerKeyInput | null;
  isRequired?: boolean | null;
  options?: ReadonlyArray<WebFormQuestionOptionInput> | null;
  richEditorBody: string;
  type: WebFormQuestionType;
};
export type WebFormQuestionOptionInput = {
  id: string;
  label: string;
};
export type WebFormQuestionAnswerKeyInput = {
  correctIds: ReadonlyArray<string>;
  feedback?: string | null;
};
export type OccurrenceHostInput = {
  organizationMembershipId?: string | null;
  productMembershipId?: string | null;
};
export type OccurrenceReminderInput = {
  audience: OccurrenceReminderAudience;
  emailRichEditorBody: string;
  emailSubject: string;
  id?: string | null;
  timing: string;
};
export type EventDrawerTitleMutation$variables = {
  input: EditEventInput;
};
export type EventDrawerTitleMutation$data = {
  readonly response: {
    readonly errors: ReadonlyArray<{
      readonly field: string;
      readonly message: string;
    }> | null;
    readonly node: {
      readonly content: {
        readonly name: string | null;
      };
      readonly id: string;
      readonly name: string | null;
    } | null;
  };
};
export type EventDrawerTitleMutation = {
  response: EventDrawerTitleMutation$data;
  variables: EventDrawerTitleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ValidationError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventDrawerTitleMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "EditEventResponse",
        "kind": "LinkedField",
        "name": "editEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Occurrence",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventDrawerTitleMutation",
    "selections": [
      {
        "alias": "response",
        "args": (v1/*: any*/),
        "concreteType": "EditEventResponse",
        "kind": "LinkedField",
        "name": "editEvent",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Occurrence",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Content",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a7fbc1c1d6d7c1d29d63095b3ccd902",
    "id": null,
    "metadata": {},
    "name": "EventDrawerTitleMutation",
    "operationKind": "mutation",
    "text": "mutation EventDrawerTitleMutation(\n  $input: EditEventInput!\n) {\n  response: editEvent(input: $input) {\n    node {\n      id\n      name\n      content {\n        name\n        id\n      }\n    }\n    errors {\n      field\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c4fc63006ad9deca4673599e2863b401";

export default node;
